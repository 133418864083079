import React, { createContext, useContext, useEffect, useState, useCallback, useRef } from 'react';
import { useAuth } from './AuthContext';
import { supabase } from './supabaseClient';

const UnreadMessagesContext = createContext();

export const UnreadMessagesProvider = ({ children }) => {
    const { userId } = useAuth();
    const [unreadMessages, setUnreadMessages] = useState({});
    const [error, setError] = useState(null);
    const pollingIntervalRef = useRef(null);

    const fetchUnreadMessages = useCallback(async () => {
        if (!userId) return;

        try {
            const { data, error } = await supabase
                .from('messages')
                .select('conversation_id')
                .eq('receiver_id', userId.toString())
                .eq('is_read', false);

            if (error) throw error;

            const unreadCounts = data.reduce((acc, message) => {
                acc[message.conversation_id] = (acc[message.conversation_id] || 0) + 1;
                return acc;
            }, {});

            setUnreadMessages(unreadCounts);
        } catch (err) {
            console.error('Error fetching unread messages:', err);
            setError(`Error fetching unread messages: ${err.message}`);
        }
    }, [userId]);

    useEffect(() => {
        if (!userId) return;

        fetchUnreadMessages(); // Fetch immediately

        pollingIntervalRef.current = setInterval(() => {
            fetchUnreadMessages();
        }, 120000); 

        return () => {
            if (pollingIntervalRef.current) {
                clearInterval(pollingIntervalRef.current); 
            }
        };
    }, [userId, fetchUnreadMessages]);

    const clearUnreadMessages = useCallback(async (conversationId) => {
        if (!userId) return;
    
        try {
            const { data, error } = await supabase
                .from('messages')
                .update({ is_read: true })
                .eq('conversation_id', conversationId)
                .eq('receiver_id', userId)
                .eq('is_read', false);
    
            if (error) throw error;
    
            console.log(`Messages updated:`, data); // Log the updated messages
    
            if (data && data.length > 0) {
                console.log(`Updated ${data.length} messages to read`);
                setUnreadMessages(prevState => {
                    const newState = { ...prevState };
                    delete newState[conversationId];
                    return newState;
                });
            }
    
            setTimeout(async () => {
                await fetchUnreadMessages();
            }, 500);
            
        } catch (error) {
            console.error("Error clearing unread messages:", error);
            setError(`Error clearing unread messages: ${error.message}`);
        }
    }, [userId, fetchUnreadMessages]);

    const totalUnreadMessages = Object.values(unreadMessages).reduce((acc, curr) => acc + curr, 0);

    return (
        <UnreadMessagesContext.Provider value={{
            unreadMessages,
            clearUnreadMessages,
            totalUnreadMessages,
            error
        }}>
            {children}
        </UnreadMessagesContext.Provider>
    );
};

export const useUnreadMessages = () => useContext(UnreadMessagesContext);