export const getCommissionPercentage = (price) => {
    if (price <= 50) {
        return 0.20; 
    } else if (price <= 100) {
        return 0.15; 
    } else {
        return 0.10;
    }
};

const config = {
    API_URL: 'https://flask-app-433916.appspot.com',
    // API_URL: 'https://staging-dot-flask-app-433916.appspot.com',
    // API_URL: 'localhost:4999'    
  };
  
  export default config;