import { useEffect } from 'react';
import { supabase } from './supabaseClient';

const useTabVisibility = () => {
  useEffect(() => {
    let lastInactiveTime = Date.now();

    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        const timeNow = Date.now();
        const timeSinceInactive = timeNow - lastInactiveTime;

        const inactivityThreshold = 15 * 60 * 1000;

        if (timeSinceInactive > inactivityThreshold) {
          const { data: { session }, error } = await supabase.auth.getSession();

          if (!session || error) {
            window.location.reload();
          }
        }

        lastInactiveTime = timeNow; 
      } else {
        lastInactiveTime = Date.now(); 
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};

export default useTabVisibility;