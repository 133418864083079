import React, { useState, useEffect } from 'react';

const CATEGORIES = [
  "Cleaning", "Handyman", "Tutoring", "Pets", "Gardening",
  "Moving", "Car Related", "IT Set Up", "Selfcare", "Baby Sitting", "Others"
];

const AVAILABILITY_OPTIONS = ['Morning', 'Afternoon', 'Evening'];
const DAY_PREFERENCES = ['Weekday', 'Weekend'];
const WEEKEND_DAYS = ['Saturday', 'Sunday'];
const WEEKDAY_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const BrowseSideBar = ({ 
  isMobile,
  showMap,
  toggleMapView,
  services,
  onFilteredServicesChange,
  selectedCategory
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [priceFilters, setPriceFilters] = useState({
    under20: false,
    under40: false,
    under60: false,
    above60: false,
  });
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedAvailability, setSelectedAvailability] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);

  const checkAvailability = (service, availability, days) => {
    console.log("Checking service:", {
      id: service.id,
      availableDays: service.available_days,
      availability,
      selectedDays: days,
      weekdayTimes: service.weekday_available_times,
      weekendTimes: service.weekend_available_times
    });

    const weekdayTimes = service.weekday_available_times || [];
    const weekendTimes = service.weekend_available_times || [];

    if (days.length === 0 && availability.length === 0) {
      return true;
    }

    if (days.length > 0) {
      let dayMatches = false;
      for (const day of days) {
        if (day === 'Weekday' && isWeekdayService(service)) {
          dayMatches = true;
        }
        if (day === 'Weekend' && isWeekendService(service)) {
          dayMatches = true;
        }
      }
      if (!dayMatches) return false;
    }

    if (availability.length === 0) {
      return true;
    }

    return availability.some(time => {
      if (weekdayTimes.includes('Flexible') || weekendTimes.includes('Flexible')) {
        return true;
      }
      return weekdayTimes.includes(time) || weekendTimes.includes(time);
    });
  };

  const isWeekendService = (service) => {
    const availableDays = service.available_days || [];
    if (availableDays.includes('Flexible')) return true;
    return WEEKEND_DAYS.some(day => availableDays.includes(day));
  };

  const isWeekdayService = (service) => {
    const availableDays = service.available_days || [];
    if (availableDays.includes('Flexible')) return true;
    return WEEKDAY_DAYS.some(day => availableDays.includes(day));
  };

  const handleAvailabilityChange = (availability) => {
    const newSelectedAvailability = selectedAvailability.includes(availability)
      ? selectedAvailability.filter(a => a !== availability)
      : [...selectedAvailability, availability];
    
    setSelectedAvailability(newSelectedAvailability);
    applyFilters(searchQuery, priceFilters, selectedCategories, newSelectedAvailability, selectedDays);
  };

  useEffect(() => {
    if (selectedCategory) {
      setSelectedCategories([selectedCategory]);
      applyFilters(searchQuery, priceFilters, [selectedCategory], [], []);
    }
  }, [selectedCategory]);

  const applyFilters = (query, prices, categories, availability, days) => {
    const filteredServices = services.filter((service) => {
      const searchQueryLower = query.toLowerCase();

      const matchesSearchQuery = 
      service.service_name.toLowerCase().includes(query.toLowerCase()) ||
      (service.service_details && service.service_details.toLowerCase().includes(searchQueryLower)) ||
      (service.service_area && service.service_area.toLowerCase().includes(searchQueryLower));
      
      const matchesPriceFilter =
        (prices.under20 && parseFloat(service.price) < 20) ||
        (prices.under40 && parseFloat(service.price) < 40) ||
        (prices.under60 && parseFloat(service.price) < 60) ||
        (prices.above60 && parseFloat(service.price) >= 60) ||
        (!prices.under20 && !prices.under40 && !prices.under60 && !prices.above60);

      const matchesCategoryFilter =
        categories.length === 0 || categories.includes(service.service_category);

      const matchesAvailability = checkAvailability(service, availability, days);

      console.log("Filter results for service", service.id, {
        matchesSearchQuery,
        matchesPriceFilter,
        matchesCategoryFilter,
        matchesAvailability
      });

      return matchesSearchQuery && matchesPriceFilter && matchesCategoryFilter && matchesAvailability;
    });
    onFilteredServicesChange(filteredServices);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    applyFilters(query, priceFilters, selectedCategories, selectedAvailability, selectedDays);
  };

  const handlePriceFilterChange = (filter) => {
    const newPriceFilters = {
      ...priceFilters,
      [filter]: !priceFilters[filter],
    };
    setPriceFilters(newPriceFilters);
    applyFilters(searchQuery, newPriceFilters, selectedCategories, selectedAvailability, selectedDays);
  };

  const handleCategoryFilterChange = (category) => {
    const newSelectedCategories = selectedCategories.includes(category)
      ? selectedCategories.filter((c) => c !== category)
      : [...selectedCategories, category];
    
    setSelectedCategories(newSelectedCategories);
    applyFilters(searchQuery, priceFilters, newSelectedCategories, selectedAvailability, selectedDays);
  };

  const handleDayChange = (day) => {
    const newSelectedDays = selectedDays.includes(day)
      ? selectedDays.filter(d => d !== day)
      : [...selectedDays, day];
    
    setSelectedDays(newSelectedDays);
    applyFilters(searchQuery, priceFilters, selectedCategories, selectedAvailability, newSelectedDays);
  };

  const isFilterActive = () => {
    return (
      searchQuery.trim() !== '' ||
      Object.values(priceFilters).some((filter) => filter) ||
      selectedCategories.length > 0 ||
      selectedAvailability.length > 0 ||
      selectedDays.length > 0
    );
  };

  return (
    <div className={`filter-sidebar ${isMobile && isFilterActive() ? 'highlight' : ''}`}>
      {!isMobile && ( 
        <button
          className={`toggle-map-btn ${showMap ? 'map-active' : ''}`}
          onClick={toggleMapView}
        >
          <span className="button-text">{showMap ? 'Back to services' : 'Map view'}</span>
        </button>
      )}
      <input
        type="text"
        placeholder="Search services"
        value={searchQuery}
        onChange={handleSearch}
      />

        <div className="category-filter">
            <h3>Service Category</h3>
            {CATEGORIES.map((category) => (
            <div key={category} className="category-option">
                <input
                type="checkbox"
                id={category}
                checked={selectedCategories.includes(category)}
                onChange={() => handleCategoryFilterChange(category)}
                />
                <label htmlFor={category}>{category}</label>
            </div>
            ))}
        </div>

        <div className="availability-filter">
            <h3>Availability</h3>
                {DAY_PREFERENCES.map((day) => (
                    <div key={day} className="availability-option">
                    <input
                        type="checkbox"
                        id={`day-${day}`}
                        checked={selectedDays.includes(day)}
                        onChange={() => handleDayChange(day)}
                    />
                    <label htmlFor={`day-${day}`}>{day}</label>
                    </div>
                ))}
                {AVAILABILITY_OPTIONS.map((time) => (
                <div key={time} className="availability-option">
                    <input
                    type="checkbox"
                    id={`availability-${time}`}
                    checked={selectedAvailability.includes(time)}
                    onChange={() => handleAvailabilityChange(time)}
                    />
                    <label htmlFor={`availability-${time}`}>{time}</label>
                </div>
                ))}
        </div>

        <div className="price-filter">
            <h3>Price range by hour</h3>
            <div className="price-option">
            <input
                type="checkbox"
                id="under20"
                checked={priceFilters.under20}
                onChange={() => handlePriceFilterChange('under20')}
            />
            <label htmlFor="under20">Under £20</label>
            </div>
            <div className="price-option">
            <input
                type="checkbox"
                id="under40"
                checked={priceFilters.under40}
                onChange={() => handlePriceFilterChange('under40')}
            />
            <label htmlFor="under40">Under £40</label>
            </div>

            <div className="price-option">
            <input
                type="checkbox"
                id="under60"
                checked={priceFilters.under60}
                onChange={() => handlePriceFilterChange('under60')}
            />
            <label htmlFor="under60">Under £60</label>
            </div>

            <div className="price-option">
            <input
                type="checkbox"
                id="above60"
                checked={priceFilters.above60}
                onChange={() => handlePriceFilterChange('above60')}
            />
            <label htmlFor="above60">£60 above</label>
            </div>
        </div>
    </div>
  );
};

export default BrowseSideBar;